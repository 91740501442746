<template>
  <b-card header="Filtros">
        <b-form-row>
          <b-col md="4">
            <b-form-group label="Región">
              <b-form-select
                v-model="busqueda.region_id"
                :options="listadoRegiones"
                value-field="region_id"
                text-field="nom_region"
                @change="buscar"
              >
                <b-form-select-option :value="null">Todos</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-form-group label="Ciclo">
              <b-form-select
                v-model="busqueda.ciclo_id"
                :options="listadoCiclos"
                value-field="ciclo_id"
                text-field="nom_ciclo"
                @change="buscar"
              >
                <b-form-select-option :value="null">Todos</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-form-row>
        <hr>
    </b-card>
</template>
<script >
import api from "@/api";
export default {
  name: "FiltrosActasRegion",
  props: {
    value: {
        type: Object,
        required: true
      },
  },
  data() {
    return {
      busqueda: this.value,
      listadoRegiones: [],
      listadoCiclos: [],
    };
  },

  validations() {
    return {
      
    };
  },
  methods: {
    buscar() {
      this.$emit('input', { ...this.busqueda });
    },
    limpiarBusqueda(ciclo_id) {
      this.busqueda = {
        region_id: null,
        ciclo_id: ciclo_id,

      };
      this.$emit('input', { ...this.busqueda });
    },
    actualizarRegiones() {
      api.listarRegiones().then((r) => {
        if (Array.isArray(r.data)) {
          this.listadoRegiones = r.data;
        } else {
          this.listadoRegiones = [];
        }
      });
    },
    actualizarCiclos() {
      api.listarCiclos().then((r) => {
        if (Array.isArray(r.data)) {
          this.listadoCiclos = r.data;
          const ciclo = this.listadoCiclos.find(c => c.ciclo_vigente);
          this.limpiarBusqueda(ciclo.ciclo_id ?? null);
        } else {
          this.listadoCiclos = [];
        }
      });
    },    
    inicializa() {

      this.actualizarRegiones();
      this.actualizarCiclos();
    }
  },
  mounted() {
    this.inicializa()
  },
}

</script>

<style>

  .input-select-container {
    display: flex; /* Hace que los elementos hijos se muestren en línea */
  }

  .input-select-container select.custom-width,
  .input-select-container input.custom-width {
    width: 250px; /* Establecer un ancho específico */
    margin-right: 10px; /* Espacio a la derecha entre los elementos del contenedor */
  }

  .button-container {
    /* Estilos para el contenedor del botón */
    display: flex;
    align-items: flex-end; /* Alinear el botón en la parte inferior */
  }

</style>