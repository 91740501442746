<template>
  <div>
    <div class="card">
      <h2 class="card-header">Formato S-04: Directorio</h2>
      <div id="tabla-encabezado"> <!-- Datos del reporte -->
        <table id="mi-tabla">
          <tbody>
            <tr>
              <th class="titulo">Diócesis:</th>
              <td> {{ reportes[0].nom_diocesis }} </td>
            </tr>
            <tr>
              <th class="titulo">Sector:</th>
              <td>{{ reportes[0].nom_sector }}</td>
            </tr>
            <tr>
              <th class="titulo">Ciclo:</th>
              <td>{{ reportes[0].ciclo }}</td>
            </tr>
          </tbody>
        </table>
      </div> <!-- Del contendor Datos del Reporte -->

      <div id="contenedor"  v-for="reporte in reportes" :key="reporte.membresia_id"> <!-- Contenedor Principal-->
        <div class="card-body"> <!--Datos principales Él y Ella-->
          <div class="form-row">
            <table>
              <thead>
                <tr>
                  <td style = "display:inline-table"></td>
                  <th>Nombres</th>
                  <th>Primer Apellido</th>
                  <th>Segundo Apellido</th>
                  <th>Teléfono</th>
                </tr>
              </thead>
              <tbody>
                <tr> <!--Renglón 1-->
                  <td scope="rowEl">Él</td>
                  <td class="text-center">{{ reporte.nombre }}</td>
                  <td class="text-center">{{ reporte.apellidop }}</td>
                  <td class="text-center">{{ reporte.apellidom }}</td>
                  <td class="text-center">{{ reporte.celular }}</td>
                </tr>
                <tr> <!--Renglón 2-->
                  <td scope="rowElla">Ella</td>
                  <td class="text-center">{{ reporte.nombre_ella }}</td>
                  <td class="text-center">{{ reporte.apellidop_ella }}</td>
                  <td class="text-center">{{ reporte.apellidom_ella }}</td>
                  <td class="text-center">{{ reporte.celular_ella }}</td>
                </tr>
                <tr>
                  <th style="text-align: right;">ID:</th>
                  <td>{{ reporte.membresia_id }}</td>
                  <th style="text-align: right;">Aniversario (dia-mes-año):</th>
                  <td colspan="2">{{ getFormattedDate(reporte.aniversario) }}</td>
                </tr>
              </tbody>
            </table>
          </div> <!-- form-row form-row Tabla-->
        </div> <!-- card-body Datos principales Él y Ella -->

        <div class="card-body"> <!--Datos del Domicilio-->
          <div class="form-row">
            <table>
              <thead>
                <tr>
                  <th colspan="2" scope="rowFondoNegro">Domicilio Particular:</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th style="text-align: right; width: 15%;">Municipio</th>
                  <td style="width: 20%;"></td>
                  <th style="text-align: right; width: 15%;">Colonia</th>
                  <td style="width: 30%;"></td>
                  <th style="text-align: right; width: 10%;">Teléfono</th>
                  <td style="width: 10%;"></td>
                </tr>
                  <tr>
                    <th style="text-align: right;">E-Mail:</th>
                    <td colspan="2"></td>
                  </tr>
              </tbody>
            </table>
          </div> <!-- form-row form-row Tabla-->
        </div> <!-- card-body Datos del Domicilio -->

        <div id="tabla-RolPrincipal"> <!-- Rol Principal -->
          <table>
            <tbody>
              <tr>
                <th scope="rowFondoNegro">Equipero</th>
                <td class="text-center" style="width: 20%;"></td>
                <td style = "display:inline-table;"></td>
                <th scope="rowFondoNegro">Promotor</th>
                <td class="text-center" style="width: 20%;"></td>
                <td style = "display:inline-table;"></td>
                <th scope="rowFondoNegro">Otro</th>
                <td class="text-center" style="width: 60%;"></td>
              </tr>
            </tbody>
          </table>
        </div> <!-- Rol Principal -->
      </div>  <!-- Contenedor Principal-->
    </div> <!-- Del Card principal-->
  </div> <!-- Del Template-->
</template>
<script>
import api from "@/api";
import { Constantes } from "@/api";
import moment from "moment";

export default {
  name: "ActasConsultar",
  components: {  },
  data() {
    return {
      Constantes: Constantes,
      reportes: {},
      guardando: false,
      permisos: {
        agregar: ["", "acta-agregar"],
        editar: ["", "acta-editar"],
        estatus: ["", "acta-estatus"],
      },
    };
  },
  //  };
  methods: {
    getFormattedDate(date) {
        return moment(date).format("DD-MM-YYYY")
    },
    reporteS04: function (sector) {
      api.reporteS04(sector).then((r) => {
        this.reportes = r.data; 
      });
    },

  },
  computed: {},
  mounted: function () {
    const sector = this.$store.getters.usuario.sector.sector_id;

    this.reporteS04(sector);
  },
};
</script>
<style scoped>
.acuerdo-number {
  font-size: 1.2em; /* Tamaño de fuente más grande */
  color: #007bff; /* Color de resaltado, puedes ajustarlo a tu preferencia */
  font-weight: bold; /* Texto en negrita */
}

.underlined-text {
  text-decoration: underline; /* Subrayar el texto */
}

table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

body {
  margin: 0;
  padding: 0;
  display: flex;
}

#tabla-encabezado {
  width: 15%;
  padding: 20px; /* Espacio para otros contenidos o espacio en blanco */
  text-align: right; /* Alineación a la derecha */
}

#tabla-RolPrincipal {
  width: 52%;
  padding: 20px; /* Espacio para otros contenidos o espacio en blanco */
  
  text-align: right; /* Alineación a la derecha */
}

#mi-tabla {
  width: 100%;
  border-collapse: collapse;
}

#mi-tabla th, #mi-tabla td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align:left;
}

#mi-tabla th {
  background-color: #f2f2f2;
}

.titulo {
  font-weight: bold;
}

#contenedor {
  width: 100%;
  border: 1px solid #cccc;
  padding: 10px;
  position: relative;
  align-self: center;
}

#cuadro-firma {
  width: 100%;
  height: 150px; /* Ajusta la altura según tus necesidades */
  border: 1px solid #000;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

#linea-firma, #linea-cargo {
  width: 80%; /* Ajusta el ancho según tus necesidades */
  border-top: 1px solid #000;
  margin-top: 25px;
}

#label-el, #label-cargo {
  width: 100%;
  text-align: center;
  font-weight: bold;
  margin-top: 5px;
}

tr td[scope="rowEl"] {
  background-color: #585858;
  color: white;
  font-weight: bold;
  text-align: right;
}

tr td[scope="rowElla"] {
  background-color: #585858;
  color: white;
  font-weight: bold;
  text-align: right;
}

tr th[scope="rowFondoNegro"] {
  background-color: #585858;
  color: white;
  font-weight: bold;
  text-align: left;
}

</style>
