import Vue from 'vue';
import VueRouter from 'vue-router';
import authRoutes from './auth';
import actasRoutes from './actas';
import cbfRoutes from './cbf';
import configRoutes from './configuracion';
import evaluationRoutes from './evaluacion';
import eventsRoutes from './eventos';
import membersRoutes from './miembros';
import reportRoutes from './reportes';

Vue.use(VueRouter);

const routes = [
  ...authRoutes,
  ...actasRoutes,
  ...cbfRoutes,
  ...configRoutes,
  ...evaluationRoutes,
  ...eventsRoutes,
  ...membersRoutes,
  ...reportRoutes
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
