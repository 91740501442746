import ReporteD01 from '@/views/Reporte_D-01.vue';
import ReporteDJ01 from '@/views/Reporte_DJ-01.vue';
import ReporteDA01 from '@/views/Reporte_DA-01.vue';
import ReporteDMR01 from '@/views/Reporte_DMR-01.vue';
import ReporteS03 from '@/views/Reporte_S-03.vue';
import ReporteSJ03 from '@/views/Reporte_SJ-03.vue';
import ReporteSA03 from '@/views/Reporte_SA-03.vue';
import ReporteSMR03 from '@/views/Reporte_SMR-03.vue';
import ReporteS04 from '@/views/Reporte_S-04.vue';
import ReporteS07 from '@/views/Reporte_S-07.vue';
import Reportes from '@/views/Reportes.vue';
import Graficas from '@/views/Graficas.vue';
import store from '@/store';

export default [
  {
    path: '/d01/:id',
    name: 'Reporte_D-01',
    component: ReporteD01
  },
  {
    path: '/dj01/:id',
    name: 'Reporte_DJ-01',
    component: ReporteDJ01
  },
  {
    path: '/da01/:id',
    name: 'Reporte_DA-01',
    component: ReporteDA01
  },
  {
    path: '/dmr01/:id',
    name: 'Reporte_DMR-01',
    component: ReporteDMR01
  },
  {
    path: '/s03/:id',
    name: 'Reporte_S-03',
    component: ReporteS03
  },
  {
    path: '/sj03/:id',
    name: 'Reporte_SJ-03',
    component: ReporteSJ03
  },
  {
    path: '/sa03/:id',
    name: 'Reporte_SA-03',
    component: ReporteSA03
  },
  {
    path: '/smr03/:id',
    name: 'Reporte_SMR-03',
    component: ReporteSMR03
  },
  {
    path: '/cbf/s04/:id',
    name: 'Reporte_S-04',
    component: ReporteS04
  },
  {
    path: '/s07/:id',
    name: 'Reporte_S-07',
    component: ReporteS07
  },
  {
    path: '/reportes/:tipo',
    name: 'Reportes',
    component: Reportes,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next();
      } else {
        next('/sinpermisos');
      }
    }
  },
  {
    path: '/graficas',
    name: 'Graficas',
    component: Graficas
  },
];
